// Here you can add other styles
.custom-card {
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 15px;
    -webkit-box-shadow: 0 0 20px rgba(8, 21, 66, 0.5);
    box-shadow: 0 0 20px rgba(8, 21, 66, 0.08);
    padding: 30px;
    background-color: white;

    .user-profile-div {
        padding: 0 0 30px;
    }

    .heading {
        border-bottom: 1px solid #eee;
        padding-bottom: 28px;
        margin-bottom: 30px;
        font-size: 24px;
    }


    .job-details {
        h3 {
            margin-bottom: 15px;
        }

        p {
            font-size: 16px !important;
        }
    }
}

.custom-card {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    -webkit-box-shadow: 0 0 20px rgba(8, 21, 66, 0.5);
    box-shadow: 0 0 20px rgba(8, 21, 66, 0.08);
    padding: 30px;
    background-color: white;

    .user-profile-div {
        padding: 0 0 30px;
    }

    .heading {
        border-bottom: 1px solid #eee;
        padding-bottom: 28px;
        margin-bottom: 30px;
        font-size: 24px;
    }

    .user-profile-img {
        width: 200px;
        height: 200px;
    }
}

.user-card-img {
    width: 80px;
    height: 80px;
    margin-left: 15px;
}

.user-card-img1 {
    width: 80px;
    height: 80px;
    margin-left: 15px;
    margin-right: 15px;
}

.pagination-div a {
    color: #2948ff;
    text-decoration: none;
}

.pagination-div span {
    background-color: #2948ff;
    color: white;
    padding: 0.5rem;
    border-radius: 100px;
    line-height: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 1rem;
}
.cursor-pointer{
    cursor: pointer;
}

.loader-div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.75);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: progress;

    img {
        max-width: 28rem;
        width: 100%;
    }
}